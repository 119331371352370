import { InfoCircleTwoTone, LoadingOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import { useNavigate } from "react-router-dom";
import { saveSubscriberRecord } from "../../../apis/subscriberdetailapi";
import arrow from "../../../images/caret-right-solid.svg";
import { CELLNINJACOLOR } from "../../../utils/constants";
import { fetchCustomerMessages } from "../../app/mainIndex";
import "./CreateAccount.scss";

const CreateAccount = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [show, setShow] = useState(false);
  const [finalValues, setFinalValues] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const emailRegex = new RegExp(
    /^[^.]+@(?!me|mac|icloud|gmail|googlemail|hotmail|live|msn|outlook|yahoo|ymail|aol)[a-z]+\.[a-z]{2,3}$/
  );

  const checkBoxesEntityName = [
    "talkingOrTextingWhileDriving",
    "areaOfCrime",
    "locationAndMovements",
    "verifyTestimonyOrAbilis",
    // "verifySomeonesResidency",
    "workplaceAccidents",
    "cellPhoneExhibitsForTrial",
    // "helpAsAnInvestigator",
  ];
  const options = [
    {
      label: "Determine if someone was talking or texting while driving",
      value: "talkingOrTextingWhileDriving",
    },
    {
      label: "Determine if someone was in the area of a crime",
      value: "areaOfCrime",
    },
    {
      label: "Determine someone's general location and movements",
      value: "locationAndMovements",
    },
    {
      label: "Verify testimony or alibis",
      value: "verifyTestimonyOrAbilis",
    },
    // {
    //     label: `Verify someone's residency`,
    //     value: "verifySomeonesResidency",
    // },
    {
      label: "Assist in workplace accidents",
      value: "workplaceAccidents",
    },
    {
      label: "Create cell phone exhibits for trial",
      value: "cellPhoneExhibitsForTrial",
    },
    // {
    //     label: "Help me as an investigator",
    //     value: "helpAsAnInvestigator",
    // },
  ];

  useEffect(() => {
    if (successMessage !== "") {
      setLoading(false);
      setSuccess(true);
    }
  }, [successMessage]);

  const subscriberDetails = {
    firstName: "",
    lastName: "",
    title: "",
    email: "",
    website: "",
    phoneNumber: "",
    organization: "",
    noOfPotentialCustomer: "",
    suggestion: "",
    other: "",
  };

  const saveSubscriber = (values) => {
    setLoading(true);
    saveSubscriberRecord(values)
      .then((response) => {
        if (response.status === 200) {
          setSuccessMessage(response.data.message);
          form.resetFields();
        } else {
          message.error(response.data.message);
          setLoading(false);
        }
      })
      .catch((e) => {
        message.error(e.response.data.message);
        setLoading(false);
        // form.resetFields();
      });
  };

  function onChanged(checkedValues) {
    setFinalValues(checkedValues);
  }

  function onOthersChange() {
    setShow(!show);
  }

  const onFinish = (values) => {
    subscriberDetails.firstName = values.firstName;
    subscriberDetails.lastName = values.lastName;
    subscriberDetails.title = values.title;
    subscriberDetails.email = values.emails;
    subscriberDetails.website = values.website;
    subscriberDetails.userName = values.userName;
    subscriberDetails.password = values.password;
    subscriberDetails.phoneNumber = values.phoneNumber;
    subscriberDetails.organization = values.organization;
    subscriberDetails.other = inputValue;
    if (finalValues.length > 0) {
      finalValues.forEach((item) => {
        if (checkBoxesEntityName.includes(item)) {
          subscriberDetails[item] = true;
        }
      });
    }
    saveSubscriber(subscriberDetails);
  };

  const loadingIcon = (
    <LoadingOutlined
      style={{
        fontSize: 50,
      }}
      spin
    />
  );

  return (
    <>
      <Spin
        indicator={loadingIcon}
        spinning={loading}
        style={{ color: CELLNINJACOLOR }}
        className="spin-loading"
      >
        <div className="main-container-create-account">
          <div className="content-container">
            <div className="heading-con">
              <span className="contact"> Register </span>
              <span className="us">for a Free Cell Phone Records Analysis</span>
            </div>
            <div className="contact-us-content-con">
              <div className="first-col">
                <div className="sub-headline-con">
                  <p className="sub-headline-is">
                    Send us cell phone records from a current or past case, and
                    we'll show you the difference an expert analysis from
                    CellNinja can make. Now assess potential cases faster, and
                    don't miss important evidence!
                  </p>
                  <p className="sub-headline-is">
                    CellNinja is exclusively for licensed attorneys, law
                    enforcement, licensed investigators and mobile forensic
                    investigators. Qualified users will recieve an email with
                    login information.
                  </p>
                  <ul className="ul-for-signup-page">
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} /> Best
                      evidence for civil and criminal matters
                    </li>
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} />
                      Fast and accurate reports
                    </li>
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} />
                      Easy to use and understand
                    </li>
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} />
                      Pay per use: no contracts, or software to manage
                    </li>
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} />
                      All major cellphone company records accepted
                    </li>
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} />
                      Quickly assess case viability
                    </li>
                    <li className="li-for-signuppage">
                      <img alt="" className="arrow-adjuster" src={arrow} />
                      And more!
                    </li>
                  </ul>
                </div>
              </div>
              <div className="second-col">
                <div className="form-constz">
                  <Form
                    className="form-for-signup"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 16 }}
                    form={form}
                    onFinish={onFinish}
                  >
                    <input type="hidden" name="fake-input" autoComplete="off" />
                    <div className="name-adjuster">
                      <Form.Item
                        name="firstName"
                        rules={[
                          {
                            required: true,
                            message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="First Name *"
                          className="height70"
                        />
                      </Form.Item>
                      <Form.Item
                        name="lastName"
                        rules={[
                          {
                            required: true,
                            message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                          },
                        ]}
                      >
                        <Input placeholder="Last Name *" className="height70" />
                      </Form.Item>
                    </div>
                    <Form.Item
                      name="title"
                      rules={[
                        {
                          required: true,
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                      ]}
                    >
                      <Input placeholder="Title *" className="height70" />
                    </Form.Item>
                    <Form.Item
                      name="website"
                      rules={[
                        {
                          required: true,
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                      ]}
                    >
                      <Input placeholder="Website *" className="height70" />
                    </Form.Item>
                    <Form.Item
                      name="phoneNumber"
                      className="icon-adjusting"
                      rules={[
                        {
                          required: true,
                          pattern: new RegExp(/^(?:\d{3}-\d{3}-\d{4})$/),
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                      ]}
                    >
                      <InputMask
                        mask="999-999-9999"
                        placeholder="Phone Number *"
                        className="height70 padding411"
                      />
                    </Form.Item>

                    <Form.Item
                      name="organization"
                      rules={[
                        {
                          required: true,
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Organization *"
                        className="height70"
                      />
                    </Form.Item>

                    <Form.Item
                      name="emails"
                      rules={[
                        {
                          required: true,
                          type: "email",
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                        {
                          pattern: emailRegex,
                          message:
                            "Please enter a work email address. You can choose another email account to receive your CellNinja reports.",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Work Email *"
                        suffix={
                          <Tooltip
                            title="You may designate a different email to download your CellNinja report."
                            color="#e4003b"
                          >
                            <InfoCircleTwoTone twoToneColor="#e4003b" />
                          </Tooltip>
                        }
                        className="height70"
                      />
                    </Form.Item>

                    <Form.Item
                      name="userName"
                      rules={[
                        {
                          required: true,
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                      ]}
                    >
                      <Input placeholder="User Name *" className="height70" />
                    </Form.Item>
                    <Form.Item
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                        },
                        {
                          // pattern:
                          // 	"^(?=.*\\d?)(?=.*[a-z])(?=.*[A-Z])(?=.*\\W).{10,}$",
                          // message:
                          // 	"Password must contain at least 10 characters including 1 lower case 1 upper case & 1 special character.",
                        },
                      ]}
                    >
                      <Input.Password
                        autoComplete="off"
                        placeholder="Password *"
                        minLength={10}
                        className="height70"
                      />
                    </Form.Item>
                    {/* <Form.Item
                                    // label="Numer of potential users"
                                    name="noOfPotentialCustomer"
                                    rules={[
                                        {
                                            required: true,
                                            message: "PLEASE COMPLETE THIS REQUIRED FIELD.",
                                        },
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        min={0}
                                        placeholder="Number of potential users *"
                                    />
                                </Form.Item> */}

                    <Form.Item name="suggestion">
                      <div className="chkbox-con">
                        <p className="margin-reducer-for-p">
                          {" "}
                          <span className="redness">
                            How can CellNinja help you?{" "}
                          </span>
                          (select all that apply)
                        </p>
                        <Checkbox.Group
                          className="checkbox-adjuster"
                          options={options}
                          onChange={onChanged}
                        />
                        <div className="other-chkbx-adjuster">
                          <div className="chkbox-selector">
                            <Checkbox
                              className="chkbx-inner-adjuster"
                              value="9"
                              onChange={onOthersChange}
                            >
                              Other
                            </Checkbox>
                          </div>
                          <div>
                            {show ? (
                              <textarea
                                className="input-font-adjuster"
                                type="text"
                                value={inputValue}
                                onInput={(e) => setInputValue(e.target.value)}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </Form.Item>
                    <Form.Item className="new-ac-create-btn-con">
                      <Button htmlType="submit" className="create-acc-btn">
                        Submit
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Modal
            style={{
              align: "center",
            }}
            title="Registration Complete!"
            maskClosable={false}
            closable={false}
            width={520}
            open={success}
            footer={null}
            onCancel={() => {
              setSuccess(false);
            }}
          >
            <div style={{ textAlign: "center", fontSize: "17px" }}>
              <h3>{successMessage}</h3>
            </div>

            <div style={{ textAlign: "center" }}>
              <Button
                style={{
                  width: "100px",
                  height: "40px",
                  backgroundColor: CELLNINJACOLOR,
                  color: "white",
                  fontSize: "18px",
                }}
                onClick={() => {
                  setSuccess(false);
                  navigate("/");
                  window.location.reload();
                }}
              >
                Dismiss
              </Button>
            </div>
          </Modal>
        </div>
      </Spin>
    </>
  );
};
export default CreateAccount;
