import { CaretDownOutlined } from "@ant-design/icons";
import { MenuOutlined } from "@material-ui/icons";
import { Button, Dropdown, Image, Menu, Modal } from "antd";
import SubMenu from "antd/es/menu/SubMenu";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import {
  resetSelfService,
  saveSelfServiceData,
} from "../../actions/selfServiceEntityAction";
import { downlaodSubpoenaFile } from "../../apis/settingsapi";
import { downloadedFile } from "../../functions/functions";
import CELLNINJALOGO from "../../images/CN_NEW_LOGO.png";
import PRICING from "../../images/pricing.png";
import FMCSA from "../../resources/Mobile_Phone_Rule_Fact_Sheet.pdf";
import { dispatch } from "../../store";
import { clearAuth } from "../../utils/authToken";
import {
  VIEW_ALL_PROJECTS,
  VIEW_MOBILE_META_TABLE,
  VIEW_SETTINGS,
  VIEW_SSR_TABLE,
} from "../../utils/constants";
import ChangePasswordForm from "../admin/settings/changePasswordForm";

const Navigation = () => {
  const [click, setClick] = React.useState(false);
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem("user"));
  const { currentUser = {} } = useSelector(({ app }) => app);
  const [permissions, setPermissions] = useState([]);
  const [homeTabPermission, setHomeTabPermission] = useState(false);
  const [url, setUrl] = useState(false);
  const [fmcsaModal, setFmcsaModal] = useState(false);
  const [pricingModal, setPricingModal] = useState(false);
  const [isPreviewVisible, setPreviewVisible] = useState(true);
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const handleClick = () => setClick(!click);
  const Close = () => setClick(false);

  var getSelfServiceEntity = useSelector(
    ({ selfServiceEntity }) => selfServiceEntity.entities
  );
  let lastUrl = window.location.href;
  new MutationObserver(() => {
    // if (performance.navigation.type === performance.navigation.TYPE_RELOAD) {
    if (
      JSON.parse(localStorage.getItem("user"))?.message ===
        "Request failed with status code 401" ||
      !localStorage.getItem("__CDR-Analyzer")
    ) {
      navigate("/user/login");
    }
    // }

    const url = window.location.href;

    if (url !== lastUrl) {
      lastUrl = url;
      onUrlChange();
    } else if (url.includes("/user/selfservice/reports")) {
      setUrl(true);
    }
  }).observe(document, { subtree: true, childList: true });

  function onUrlChange() {
    // alert("URL changed!", window.location.href);
    if (window.location.pathname === "/user/selfservice/reports") {
      setUrl(true);
    } else {
      setUrl(false);
    }
  }

  const fetchPermissions = () => {
    const uniquePermissions = {};
    if (currentUser && currentUser.roles) {
      currentUser.roles.forEach((role) => {
        role.permissions.forEach((permission) => {
          uniquePermissions[permission.id] = permission;
        });
      });
      setPermissions(Object.values(uniquePermissions));
    }
  };

  const checkHomeTabPermissions = async () => {
    if (permissions.length > 0) {
      permissions.forEach((permission) => {
        if (
          permission.name === VIEW_ALL_PROJECTS ||
          permission.name === VIEW_SSR_TABLE ||
          permission.name === VIEW_MOBILE_META_TABLE ||
          permission.name === VIEW_SETTINGS
        ) {
          setHomeTabPermission(!homeTabPermission);
        }
      });
    }
  };

  const clearRedux = () => {
    dispatch(resetSelfService());
    getSelfServiceEntity.uploadedFiles.voice = "";
    getSelfServiceEntity.uploadedFiles.data = "";
    getSelfServiceEntity.uploadedFiles.sms = "";
    getSelfServiceEntity.uploadedFiles.ipSessions = "";
    getSelfServiceEntity.uploadedFiles.tower = "";

    dispatch(saveSelfServiceData("filesToUpload", []));
    dispatch(saveSelfServiceData());
  };

  useEffect(() => {
    fetchPermissions();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser]);

  useEffect(() => {
    checkHomeTabPermissions();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser, permissions]);
  const subMenu = (
    <Menu hidden="" style={{ padding: "5px", borderRadius: "8px" }}>
      <div style={{ display: "flex", pointerEvents: "none" }}>
        <div>
          <Button
            shape="circle"
            style={{
              margin: "5px",
              backgroundColor: "#e2cece",
            }}
            onClick={click ? handleClick : null}
          >
            {user ? user?.name[0] : ""}
          </Button>
        </div>
        <div>
          <div onClick={click ? handleClick : null}>
            <strong>{user?.name}</strong>
          </div>
          <div onClick={click ? handleClick : null}>{user?.email}</div>
        </div>
      </div>

      <Menu.Item
        key="password"
        // onClick={click ? handleClick : null}
        onClick={() => {
          changePassword();
          if (click) handleClick();
        }}
      >
        Change Password
      </Menu.Item>
      <a href="/">
        <Menu.Item
          key="logout"
          onClick={() => {
            handleSignOut();
            if (click) handleClick();
          }}
        >
          Logout
        </Menu.Item>
      </a>
    </Menu>
  );

  const downloadSubpoenaFile = (value) => {
    let fileName;

    if (value === 1) {
      fileName = "CellNinja Subpoena Language AT&T.doc";
    } else if (value === 2) {
      fileName = "CellNinja Subpoena Language Verizon.doc";
    } else if (value === 3) {
      fileName = "CellNinja Subpoena Language T-Mobile.doc";
    }
    
    downlaodSubpoenaFile(value).then((response) => {
      if (response.status === 200) {
        downloadedFile(response, fileName);
      }
    });
  };

  const resourcesMenu = (
    <Menu hidden="">
      {user?.userType === "Annual" ? (
        <Menu.Item key="1" onClick={click ? handleClick : null}>
          <Link to="/user/selfservice/lookup/" className="resource-item-color">
            Look-up Service
            {/* : numbers,people,emails, court records (Member
          Feature)* */}
          </Link>
        </Menu.Item>
      ) : (
        ""
      )}
      <SubMenu key="2" title="Subpoena Language">
        <Menu.Item key="2.1" onClick={click ? handleClick : null}>
          <Link onClick={() => downloadSubpoenaFile(1)}>
            CellNinja Subpoena Language AT&T
          </Link>
        </Menu.Item>
        <Menu.Item key="2.2" onClick={click ? handleClick : null}>
          <Link onClick={() => downloadSubpoenaFile(2)}>
            CellNinja Subpoena Language Verizon
          </Link>
        </Menu.Item>
        <Menu.Item key="2.3" onClick={click ? handleClick : null}>
          <Link onClick={() => downloadSubpoenaFile(3)}>
            CellNinja Subpoena Language T-Mobile
          </Link>
        </Menu.Item>
      </SubMenu>
      <Menu.Item key="3" onClick={click ? handleClick : null}>
        <Link to="/user/selfservice" className="resource-item-color">
          CellPhone Company Directory
          {/* : cell phone records request */}
        </Link>
      </Menu.Item>
      <Menu.Item
        key="4"
        className="resource-item-color"
        onClick={click ? handleClick : null}
      >
        <Link to="/user/selfservice">Distracted Driver Studies</Link>
      </Menu.Item>
      <Menu.Item
        key="5"
        className="resource-item-color"
        onClick={click ? handleClick : null}
      >
        <Link to="/user/selfservice">Case Refrence Library</Link>
      </Menu.Item>
      <Menu.Item
        key="6"
        className="resource-item-color"
        onClick={click ? handleClick : null}
      >
        <Link to="/user/selfservice">Glossary of Cellular Items</Link>
      </Menu.Item>

      <Menu.Item key="7" onClick={click ? handleClick : null}>
        <Link to="/user/selfservice" onClick={() => setFmcsaModal(true)}>
          Federal Motor Carrier Saftey Rules(FMCSA)
        </Link>
      </Menu.Item>
      <Menu.Item key="8" onClick={click ? handleClick : null}>
        <Link to="/user/selfservice">Support Services</Link>
      </Menu.Item>
    </Menu>
  );
  const handleSignOut = () => {
    // props.onLogout();
    clearAuth();
  };
  const changePassword = () => {
    setChangePasswordModal(true);
  };

  const onSave = () => {
    setChangePasswordModal(false);
    handleSignOut();
    navigate("/user/login");
  };

  return (
    <div>
      <div className="main-nav">
        {/* <div
          className={click ? "main-container" : ""}
          onClick={() => Close()}
        /> */}
        <nav className="navbar" onClick={(e) => e.stopPropagation()}>
          <div className="nav-container">
            <NavLink exact className="nav-logo">
              <img className="logo" src={CELLNINJALOGO} alt="" />
            </NavLink>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  to="/"
                  exact
                  activeClassName="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  HOME
                </NavLink>
              </li>
              {url ? (
                <li className="nav-item">
                  <NavLink
                    to="/user/selfservice/"
                    exact
                    activeClassName="active"
                    className="nav-links"
                    onClick={() => {
                      clearRedux();
                      if (click) handleClick();
                      //   click ? handleClick() : null
                    }}
                  >
                    NEW REPORT
                  </NavLink>
                </li>
              ) : (
                ""
              )}
              <li className="nav-item">
                <NavLink
                  to="/user/selfservice/reports"
                  exact
                  activeClassName="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  DASHBOARD
                </NavLink>
              </li>
              <li className="nav-item">
                <Dropdown overlay={resourcesMenu}>
                  <NavLink exact activeClassName="active" className="nav-links">
                    RESOURCES <CaretDownOutlined className="drop-icon" />
                  </NavLink>
                </Dropdown>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-links"
                  activeClassName="active"
                  onClick={() => {
                    setPricingModal(true);
                    setPreviewVisible(!isPreviewVisible);
                  }}
                >
                  PRICING
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  to="/contactus"
                  exact
                  activeClassName="active"
                  className="nav-links"
                  onClick={click ? handleClick : null}
                >
                  HELP
                </NavLink>
              </li>
              {homeTabPermission ? (
                <li className="nav-item">
                  <NavLink
                    to="/user/home/projects"
                    exact
                    activeClassName="active"
                    className="nav-links"
                    onClick={click ? handleClick : null}
                  >
                    ADMINISTRATION
                  </NavLink>
                </li>
              ) : (
                ""
              )}
            </ul>
            <div className="nav-icon" onClick={handleClick}>
              <MenuOutlined style={{ fontSize: "2rem" }} />
            </div>
          </div>
        </nav>
        <div className="user-btn">
          <Dropdown overlay={subMenu}>
            <Button shape="circle">{user?.name[0]}</Button>
          </Dropdown>
        </div>
      </div>
      <div className="fmcsa-modal">
        <Modal
          footer={null}
          // style={{ height: "580px" }}
          width="80%"
          open={fmcsaModal}
          onCancel={() => setFmcsaModal(false)}
        >
          <iframe
            title="fmcsa"
            style={{
              width: "100%",
              height: "40rem",
              minHeight: "30rem",
              borderWidth: "0px",
              marginTop: "40px",
            }}
            src={FMCSA}
          />
        </Modal>
        {/* <Modal
          footer={null}
          // style={{ height: "580px" }}
          width="80%"
          open={pricingModal}
          onCancel={() => setPricingModal(false)}
        >
          <iframe
            title="fmcsa"
            style={{
              width: "100%",
              height: "40rem",
              minHeight: "30rem",
              borderWidth: "0px",
              marginTop: "40px",
            }}
            src={FMCSA}
          />
        </Modal> */}
        {pricingModal ? (
          <div hidden={pricingModal}>
            <Image
              src={PRICING}
              preview={{
                visible: isPreviewVisible,
                onVisibleChange: (visible, prevVisible) =>
                  setPreviewVisible(visible),
              }}
            />
          </div>
        ) : (
          ""
        )}

        <Modal
          open={changePasswordModal}
          footer={null}
          title="Change Password"
          onCancel={() => setChangePasswordModal(false)}
        >
          <ChangePasswordForm
            style={{ textAlign: "-webkit-center" }}
            onSave={() => onSave()}
          />
        </Modal>
      </div>
    </div>
  );
};

export default Navigation;
